<template>
  <div>
    <div class="header active">
      <div class="container">
        <nav class="navbar navbar-inverse" role="navigation">
          <div class="navbar-header">
            <button
              type="button"
              id="nav-toggle"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#main-nav"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <img
              class="logo-app"
              :src="require('@/assets/logo_title.png')"
              width="145"
            />
            <!-- <a href="#" class="navbar-brand scroll-top"><em>Portus</em>copia</a> -->
          </div>
          <!--/.navbar-header-->
          <div id="main-nav" class="collapse navbar-collapse">
            <ul class="nav navbar-nav">
              <!-- <li><a href="#" class="scroll-top">Home</a></li> -->
              <li>
                <a href="#" class="scroll-link" data-id="about">{{
                  $t("{menuOptHow}")
                }}</a>
              </li>
              <!-- <li>
                <a href="#" class="scroll-link" data-id="portfolio"
                  >Portfolio</a
                >
              </li> -->
              <li>
                <a href="#" class="scroll-link" data-id="blog">{{
                  $t("{menuOptUseCases}")
                }}</a>
              </li>
              <!-- <li>
                <a href="#" class="scroll-link" data-id="contact">{{
                  $t("{menuOptContact}")
                }}</a>
              </li> -->
            </ul>
          </div>

          <!--/.navbar-collapse-->
        </nav>
        <!--/.navbar-->
        <select class="variable-select" v-model="localeModel">
          <option value="es">ES</option>
          <option value="en">EN</option>
        </select>
      </div>
      <!--/.container-->
    </div>
    <!--/.header-->

    <div class="parallax-content baner-content" id="home">
      <div class="container">
        <div class="text-content">
          <h2>
            <em>{{ $t("{welcomeMessage}") }}</em> <span>Portus</span>copia
          </h2>
          <p>
            {{ $t("{mainText}") }}
            <a
              style="color: #03a9f4"
              href="https://portus.puertos.es"
              target="_blank"
              ><b>Portus</b></a
            >
          </p>
          <div class="primary-white-button">
            <a href="#" @click="goApp" class="scroll-link">{{
              $t("{buttonDownload}")
            }}</a>
          </div>
        </div>
        <hr style="margin-top: 30px" />

        <div class="row" style="margin-top: 40px">
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="service-item">
              <div class="icon">
                <img src="img/graph.png" alt="" />
              </div>
              <h4>{{ localeModel === "es" ? "Predicciones" : "Forecast" }}</h4>
              <div class="line-dec"></div>
              <p>
                {{ $t("{textPredicciones}") }}
              </p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="service-item">
              <div class="icon">
                <img src="img/realtime.png" alt="" />
              </div>
              <h4>
                {{
                  localeModel === "es"
                    ? "Datos en Tiempo Real"
                    : "Real-Time Data"
                }}
              </h4>
              <div class="line-dec"></div>
              <p>
                {{ $t("{textTiempoReal}") }}
              </p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="service-item">
              <div class="icon">
                <img src="img/visualization.png" alt="" />
              </div>
              <h4>
                {{
                  localeModel === "es"
                    ? "Visualización de Datos"
                    : "Data Visualization"
                }}
              </h4>
              <div class="line-dec"></div>
              <p>
                {{ $t("{textVisualizacionDatos}") }}
              </p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="service-item">
              <div class="icon">
                <img src="img/search.png" alt="" />
              </div>
              <h4>
                {{
                  localeModel === "es"
                    ? "Búsqueda Optimizada"
                    : "Optimized Search"
                }}
              </h4>
              <div class="line-dec"></div>
              <p>
                {{ $t("{textBusquedaOptimizada}") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="about" class="page-section">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="service-item">
              <div class="icon">
                <img src="img/search.png" alt="" />
              </div>
              <h4>
                1.
                {{
                  localeModel === "es"
                    ? "Búsqueda de Catálogos"
                    : "Catalogue Search"
                }}
              </h4>
              <div class="line-dec"></div>
              <p>
                {{ $t("{textBusquedaCatalogos}") }}
              </p>
              <!-- <div class="primary-blue-button">
                <a href="#" class="scroll-link" data-id="portfolio"
                  >Continue Reading</a
                >
              </div> -->
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="service-item">
              <div class="icon">
                <img src="img/filter.png" alt="" />
              </div>
              <h4>
                2.
                {{
                  localeModel === "es"
                    ? "Filtrado de Datos"
                    : "Dataset filtering"
                }}
              </h4>
              <div class="line-dec"></div>
              <p>
                {{ $t("{textFiltradoDatos}") }}
              </p>
              <!-- <div class="primary-blue-button">
                <a href="#" class="scroll-link" data-id="portfolio"
                  >Continue Reading</a
                >
              </div> -->
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="service-item">
              <div class="icon">
                <img src="img/download.png" alt="" />
              </div>
              <h4>
                3.
                {{
                  localeModel === "es"
                    ? "Descarga en 1-click"
                    : "One-click download"
                }}
              </h4>
              <div class="line-dec"></div>
              <p>
                {{ $t("{textDescarga1Click}") }}
              </p>
              <!-- <div class="primary-blue-button">
                <a href="#" class="scroll-link" data-id="portfolio"
                  >Continue Reading</a
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="arrow">
      <a href="#" class="scroll-link" data-id="about"></a>
      <a href="#" class="scroll-link" data-id="about"> </a>
    </div>
    <div class="tabs-content" id="blog">
      <div class="container">
        <div class="row">
          <div class="wrapper">
            <div class="col-md-4">
              <div class="section-heading">
                <h4>
                  {{ localeModel === "es" ? "Casos de uso" : "Use cases" }}
                </h4>
                <div class="line-dec"></div>
                <!-- <p>
                  Le mostramos un resumen de todas las posibilidades ofrecidas
                  en Portuscopia:
                </p> -->
                <ul class="tabs clearfix" data-tabgroup="first-tab-group">
                  <li>
                    <a href="#tab1" class="active">{{
                      localeModel === "es"
                        ? "Filtros para buscar catálogos"
                        : "Using filters to search catalogues"
                    }}</a>
                  </li>
                  <li>
                    <a href="#tab2">{{
                      localeModel === "es"
                        ? "Visualización inmediata"
                        : "Direct visualizacion of data"
                    }}</a>
                  </li>
                  <li>
                    <a href="#tab3">{{
                      localeModel === "es"
                        ? "Descarga de un área seleccionada"
                        : "Download data in a selected area"
                    }}</a>
                  </li>
                  <li>
                    <a href="#tab4">{{
                      localeModel === "es"
                        ? "Descarga inmediata de ficheros"
                        : "1-click file download"
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-8">
              <section id="first-tab-group" class="tabgroup">
                <div id="tab1">
                  <img class="use-case-img" src="img/usecase1.png" alt="" />
                  <div class="text-content">
                    <!-- <h4>Nulla eget convallis augue</h4>
                    <span
                      ><a href="#">Digital Marketing</a> /
                      <a href="#">Honey</a> /
                      <a href="#">21 September 2020</a></span
                    > -->
                    <p>
                      {{ $t("{textUseCase1}") }}
                    </p>
                  </div>
                </div>
                <div id="tab2">
                  <img class="use-case-img" src="img/usecase2.png" alt="" />
                  <div class="text-content">
                    <!-- <h4>Quisque ultricies maximus</h4>
                    <span
                      ><a href="#">Branding</a> / <a href="#">David</a> /
                      <a href="#">24 August 2020</a></span
                    > -->
                    <p>
                      {{ $t("{textUseCase2}") }}
                    </p>
                  </div>
                </div>
                <div id="tab3">
                  <img class="use-case-img" src="img/usecase3.png" alt="" />
                  <div class="text-content">
                    <!-- <h4>Sed vel elit et lorem</h4>
                    <span
                      ><a href="#">Web Design</a> / <a href="#">William</a> /
                      <a href="#">18 July 2020</a></span
                    > -->
                    <p>
                      {{ $t("{textUseCase3}") }}
                    </p>
                  </div>
                </div>
                <div id="tab4">
                  <img class="use-case-img" src="img/usecase4.png" alt="" />
                  <div class="text-content">
                    <!-- <h4>Vivamus purus neque</h4>
                    <span
                      ><a href="#">E-Commerce</a> / <a href="#">George</a> /
                      <a href="#">14 July 2020</a></span
                    > -->
                    <p>
                      {{ $t("{textUseCase4}") }}
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="contact-us">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-heading">
              <h4>Contact Us</h4>
              <div class="line-dec"></div>
              <p>
                Nunc posuere non quam at pharetra. Mauris tincidunt purus vel
                ultrices malesuada. Integer euismod erat ut nulla ultrices, ut
                auctor eros feugiat.
              </p>
              <div class="pop-button">
                <h4>Send us a message</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-md-offset-3">
            <div class="pop">
              <span>✖</span>
              <form id="contact" action="#" method="post">
                <div class="row">
                  <div class="col-md-12">
                    <fieldset>
                      <input
                        name="name"
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Your name..."
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div class="col-md-12">
                    <fieldset>
                      <input
                        name="email"
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Your email..."
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div class="col-md-12">
                    <fieldset>
                      <textarea
                        name="message"
                        rows="6"
                        class="form-control"
                        id="message"
                        placeholder="Your message..."
                        required=""
                      ></textarea>
                    </fieldset>
                  </div>
                  <div class="col-md-12">
                    <fieldset>
                      <button type="submit" id="form-submit" class="btn">
                        Send Message
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <footer>
      <div class="container" id="contact">
        <div class="row">
          <div class="col-md-3 col-sm-12">
            <div class="logo">
              <a class="logo-ft scroll-top" href="#"><em>Portus</em>copia</a>
              <p>
                <a target="_blank" href="https://www.puertos.es"
                  >Puertos del Estado</a
                >
                <br />
              </p>
            </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="location">
              <h4>{{ $t("{footerLocation}") }}</h4>
              <ul>
                <li>Avenida del Partenón 10. <br />28042 Madrid</li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="contact-info">
              <h4>{{ $t("{footerMoreInfo}") }}</h4>
              <ul>
                <li>
                  <em>{{ $t("{footerPhone}") }}</em
                  >: +34 915 24 55 00
                </li>
                <li>
                  <em>{{ "E-mail" }}</em
                  >: webmaster@puertos.es
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="connect-us">
              <h4>{{ $t("{footerConnect}") }}</h4>
              <ul>
                <li>
                  <a target="_blank" href="https://twitter.com/PuertosEstado"
                    ><i class="fa fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/Organismo-P%C3%BAblico-Puertos-del-Estado-393892984038949/"
                    ><i class="fa fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/puertos-del-estado"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      localeModel: "es",
    };
  },
  created() {
    let urlParams = new URLSearchParams(
      window.location.search
        ? window.location.search
        : window.location.hash.replace("#/", "")
    );
    this.localeModel = urlParams.get("locale") ? urlParams.get("locale") : "es";
  },
  computed: {
    $t() {
      return (key) => this.$translate(key, this.localeModel);
    },
  },
  methods: {
    goApp() {
      window.location.href = "search#/?locale=" + this.localeModel;
    },
  },
};
</script>
<style scoped>
.use-case-img {
  height: 380px;
  min-width: 750px;
}
.variable-select,
.variable-select:focus {
  position: absolute;
  top: 25px;
  right: 25px;
  display: block;
  min-width: 50px;
  height: 30px;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 1.5;
  color: white;
  background-color: #363640;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0px;
}
.variable-select option {
  color: white;
  line-height: 1.5;
  background-color: #363640;
}
.logo-app {
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
</style>
