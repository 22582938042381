
import Vue from "vue";
import HomeApp from "./HomeApp";

import LocalePlugin from '@/plugins/locale.plugin'

Vue.use(LocalePlugin);

new Vue({
  render: h => h(HomeApp)
}).$mount("#app");
