var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header active" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "nav",
          {
            staticClass: "navbar navbar-inverse",
            attrs: { role: "navigation" }
          },
          [
            _c("div", { staticClass: "navbar-header" }, [
              _vm._m(0),
              _c("img", {
                staticClass: "logo-app",
                attrs: { src: require("@/assets/logo_title.png"), width: "145" }
              })
            ]),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse",
                attrs: { id: "main-nav" }
              },
              [
                _c("ul", { staticClass: "nav navbar-nav" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "scroll-link",
                        attrs: { href: "#", "data-id": "about" }
                      },
                      [_vm._v(_vm._s(_vm.$t("{menuOptHow}")))]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "scroll-link",
                        attrs: { href: "#", "data-id": "blog" }
                      },
                      [_vm._v(_vm._s(_vm.$t("{menuOptUseCases}")))]
                    )
                  ])
                ])
              ]
            )
          ]
        ),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localeModel,
                expression: "localeModel"
              }
            ],
            staticClass: "variable-select",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.localeModel = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c("option", { attrs: { value: "es" } }, [_vm._v("ES")]),
            _c("option", { attrs: { value: "en" } }, [_vm._v("EN")])
          ]
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "parallax-content baner-content", attrs: { id: "home" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "text-content" }, [
            _c("h2", [
              _c("em", [_vm._v(_vm._s(_vm.$t("{welcomeMessage}")))]),
              _vm._v(" "),
              _c("span", [_vm._v("Portus")]),
              _vm._v("copia ")
            ]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("{mainText}")) + " "),
              _vm._m(1)
            ]),
            _c("div", { staticClass: "primary-white-button" }, [
              _c(
                "a",
                {
                  staticClass: "scroll-link",
                  attrs: { href: "#" },
                  on: { click: _vm.goApp }
                },
                [_vm._v(_vm._s(_vm.$t("{buttonDownload}")))]
              )
            ])
          ]),
          _c("hr", { staticStyle: { "margin-top": "30px" } }),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "40px" } },
            [
              _c("div", { staticClass: "col-md-3 col-sm-6 col-xs-12" }, [
                _c("div", { staticClass: "service-item" }, [
                  _vm._m(2),
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        _vm.localeModel === "es" ? "Predicciones" : "Forecast"
                      )
                    )
                  ]),
                  _c("div", { staticClass: "line-dec" }),
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("{textPredicciones}")) + " ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-md-3 col-sm-6 col-xs-12" }, [
                _c("div", { staticClass: "service-item" }, [
                  _vm._m(3),
                  _c("h4", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.localeModel === "es"
                            ? "Datos en Tiempo Real"
                            : "Real-Time Data"
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "line-dec" }),
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("{textTiempoReal}")) + " ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-md-3 col-sm-6 col-xs-12" }, [
                _c("div", { staticClass: "service-item" }, [
                  _vm._m(4),
                  _c("h4", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.localeModel === "es"
                            ? "Visualización de Datos"
                            : "Data Visualization"
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "line-dec" }),
                  _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("{textVisualizacionDatos}")) + " "
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-md-3 col-sm-6 col-xs-12" }, [
                _c("div", { staticClass: "service-item" }, [
                  _vm._m(5),
                  _c("h4", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.localeModel === "es"
                            ? "Búsqueda Optimizada"
                            : "Optimized Search"
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "line-dec" }),
                  _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("{textBusquedaOptimizada}")) + " "
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ]
    ),
    _c("section", { staticClass: "page-section", attrs: { id: "about" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4 col-sm-6 col-xs-12" }, [
            _c("div", { staticClass: "service-item" }, [
              _vm._m(6),
              _c("h4", [
                _vm._v(
                  " 1. " +
                    _vm._s(
                      _vm.localeModel === "es"
                        ? "Búsqueda de Catálogos"
                        : "Catalogue Search"
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "line-dec" }),
              _c("p", [
                _vm._v(" " + _vm._s(_vm.$t("{textBusquedaCatalogos}")) + " ")
              ])
            ])
          ]),
          _c("div", { staticClass: "col-md-4 col-sm-6 col-xs-12" }, [
            _c("div", { staticClass: "service-item" }, [
              _vm._m(7),
              _c("h4", [
                _vm._v(
                  " 2. " +
                    _vm._s(
                      _vm.localeModel === "es"
                        ? "Filtrado de Datos"
                        : "Dataset filtering"
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "line-dec" }),
              _c("p", [
                _vm._v(" " + _vm._s(_vm.$t("{textFiltradoDatos}")) + " ")
              ])
            ])
          ]),
          _c("div", { staticClass: "col-md-4 col-sm-6 col-xs-12" }, [
            _c("div", { staticClass: "service-item" }, [
              _vm._m(8),
              _c("h4", [
                _vm._v(
                  " 3. " +
                    _vm._s(
                      _vm.localeModel === "es"
                        ? "Descarga en 1-click"
                        : "One-click download"
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "line-dec" }),
              _c("p", [
                _vm._v(" " + _vm._s(_vm.$t("{textDescarga1Click}")) + " ")
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._m(9),
    _c("div", { staticClass: "tabs-content", attrs: { id: "blog" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "section-heading" }, [
                _c("h4", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.localeModel === "es" ? "Casos de uso" : "Use cases"
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "line-dec" }),
                _c(
                  "ul",
                  {
                    staticClass: "tabs clearfix",
                    attrs: { "data-tabgroup": "first-tab-group" }
                  },
                  [
                    _c("li", [
                      _c(
                        "a",
                        { staticClass: "active", attrs: { href: "#tab1" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.localeModel === "es"
                                ? "Filtros para buscar catálogos"
                                : "Using filters to search catalogues"
                            )
                          )
                        ]
                      )
                    ]),
                    _c("li", [
                      _c("a", { attrs: { href: "#tab2" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.localeModel === "es"
                              ? "Visualización inmediata"
                              : "Direct visualizacion of data"
                          )
                        )
                      ])
                    ]),
                    _c("li", [
                      _c("a", { attrs: { href: "#tab3" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.localeModel === "es"
                              ? "Descarga de un área seleccionada"
                              : "Download data in a selected area"
                          )
                        )
                      ])
                    ]),
                    _c("li", [
                      _c("a", { attrs: { href: "#tab4" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.localeModel === "es"
                              ? "Descarga inmediata de ficheros"
                              : "1-click file download"
                          )
                        )
                      ])
                    ])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "col-md-8" }, [
              _c(
                "section",
                { staticClass: "tabgroup", attrs: { id: "first-tab-group" } },
                [
                  _c("div", { attrs: { id: "tab1" } }, [
                    _c("img", {
                      staticClass: "use-case-img",
                      attrs: { src: "img/usecase1.png", alt: "" }
                    }),
                    _c("div", { staticClass: "text-content" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("{textUseCase1}")) + " ")
                      ])
                    ])
                  ]),
                  _c("div", { attrs: { id: "tab2" } }, [
                    _c("img", {
                      staticClass: "use-case-img",
                      attrs: { src: "img/usecase2.png", alt: "" }
                    }),
                    _c("div", { staticClass: "text-content" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("{textUseCase2}")) + " ")
                      ])
                    ])
                  ]),
                  _c("div", { attrs: { id: "tab3" } }, [
                    _c("img", {
                      staticClass: "use-case-img",
                      attrs: { src: "img/usecase3.png", alt: "" }
                    }),
                    _c("div", { staticClass: "text-content" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("{textUseCase3}")) + " ")
                      ])
                    ])
                  ]),
                  _c("div", { attrs: { id: "tab4" } }, [
                    _c("img", {
                      staticClass: "use-case-img",
                      attrs: { src: "img/usecase4.png", alt: "" }
                    }),
                    _c("div", { staticClass: "text-content" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("{textUseCase4}")) + " ")
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]),
    _c("footer", [
      _c("div", { staticClass: "container", attrs: { id: "contact" } }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(10),
          _c("div", { staticClass: "col-md-3 col-sm-12" }, [
            _c("div", { staticClass: "location" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t("{footerLocation}")))]),
              _vm._m(11)
            ])
          ]),
          _c("div", { staticClass: "col-md-3 col-sm-12" }, [
            _c("div", { staticClass: "contact-info" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t("{footerMoreInfo}")))]),
              _c("ul", [
                _c("li", [
                  _c("em", [_vm._v(_vm._s(_vm.$t("{footerPhone}")))]),
                  _vm._v(": +34 915 24 55 00 ")
                ]),
                _c("li", [
                  _c("em", [_vm._v(_vm._s("E-mail"))]),
                  _vm._v(": webmaster@puertos.es ")
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "col-md-3 col-sm-12" }, [
            _c("div", { staticClass: "connect-us" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t("{footerConnect}")))]),
              _vm._m(12)
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggle",
        attrs: {
          type: "button",
          id: "nav-toggle",
          "data-toggle": "collapse",
          "data-target": "#main-nav"
        }
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")]),
        _c("span", { staticClass: "icon-bar" }),
        _c("span", { staticClass: "icon-bar" }),
        _c("span", { staticClass: "icon-bar" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticStyle: { color: "#03a9f4" },
        attrs: { href: "https://portus.puertos.es", target: "_blank" }
      },
      [_c("b", [_vm._v("Portus")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "img/graph.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "img/realtime.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "img/visualization.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "img/search.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "img/search.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "img/filter.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "img/download.png", alt: "" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow" }, [
      _c("a", {
        staticClass: "scroll-link",
        attrs: { href: "#", "data-id": "about" }
      }),
      _c("a", {
        staticClass: "scroll-link",
        attrs: { href: "#", "data-id": "about" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3 col-sm-12" }, [
      _c("div", { staticClass: "logo" }, [
        _c("a", { staticClass: "logo-ft scroll-top", attrs: { href: "#" } }, [
          _c("em", [_vm._v("Portus")]),
          _vm._v("copia")
        ]),
        _c("p", [
          _c(
            "a",
            { attrs: { target: "_blank", href: "https://www.puertos.es" } },
            [_vm._v("Puertos del Estado")]
          ),
          _c("br")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("Avenida del Partenón 10. "),
        _c("br"),
        _vm._v("28042 Madrid")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: "https://twitter.com/PuertosEstado"
            }
          },
          [_c("i", { staticClass: "fa fa-twitter" })]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href:
                "https://www.facebook.com/Organismo-P%C3%BAblico-Puertos-del-Estado-393892984038949/"
            }
          },
          [_c("i", { staticClass: "fa fa-facebook" })]
        )
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: "https://www.linkedin.com/company/puertos-del-estado"
            }
          },
          [_c("i", { staticClass: "fa fa-linkedin" })]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }